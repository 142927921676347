import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function Nav() {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 293 && window.innerWidth <= 768) {
                setScrolled(true);
            } else if (window.scrollY >= 85 && window.innerWidth > 768) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <nav className={scrolled ? "fixed-nav" : ""}>
            <div
                className={
                    scrolled
                        ? "nav-container nav-container_disabled"
                        : "nav-container"
                }
            >
                <div className="logo">
                    <img src="EXCSERPRO.svg" alt="" />
                </div>
                <div className="nav-contact-div">
                    {" "}
                    <div className="call">
                        <i className="fa-solid fa-phone"></i>
                        <div className="desktop-view">
                            {" "}
                            <p>Call Now</p>
                            <span>017 632 1444</span>
                        </div>
                    </div>
                    <div className="time">
                        <i className="fa-regular fa-envelope"></i>
                        <div className="desktop-view">
                            {" "}
                            <p>Email Now</p>
                            <span>info@excserpro.co.za</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navigation">
                <div className="container">
                    {" "}
                    <ul>
                        <li className={scrolled ? "good-background" : ""}>
                            <NavLink
                                className={scrolled ? "link-border-patch" : ""}
                                to={"/"}
                            >
                                HOME
                            </NavLink>
                        </li>
                        <li className={scrolled ? "good-background" : ""}>
                            <NavLink
                                className={scrolled ? "link-border-patch" : ""}
                                to={"/about"}
                            >
                                ABOUT
                            </NavLink>
                        </li>
                        <li className={scrolled ? "good-background" : ""}>
                            <NavLink
                                className={scrolled ? "link-border-patch" : ""}
                                to={"/services"}
                            >
                                SERVICES
                            </NavLink>
                        </li>
                        <li className={scrolled ? "good-background" : ""}>
                            <NavLink
                                className={scrolled ? "link-border-patch" : ""}
                                to={"/contact"}
                            >
                                CONTACT
                            </NavLink>
                        </li>
                        <li className={scrolled ? "good-background" : ""}>
                            <NavLink
                                className={scrolled ? "link-border-patch" : ""}
                                to={"/shop"}
                            >
                                SHOP
                            </NavLink>
                        </li>
                    </ul>
                    <div
                        className={
                            scrolled
                                ? "nav-social-container nav-social-container-disabled"
                                : "nav-social-container"
                        }
                    >
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://web.facebook.com/Excserpro/"
                        >
                            <i className="fa-brands fa-facebook"></i>
                        </a>

                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://wa.me/+27822071723"
                        >
                            <i className="fa-brands fa-whatsapp"></i>
                        </a>

                        <a target="_blank" rel="noopener noreferrer" href="#">
                            <i className="fa-brands fa-instagram"></i>
                        </a>

                        <a target="_blank" rel="noopener noreferrer" href="#">
                            <i className="fa-brands fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Nav;
