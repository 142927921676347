import ContactForm from "../components/ContactForm";
import "./styles/pages.css";
import "./styles/contact.css";
import SEOHelmet from "../components/SEOHelmet";

const Contact = () => {
    return (
        <>
            <SEOHelmet
                title="Contact EXCSERPRO | Get in Touch Today"
                description="Have questions or need assistance? Contact Excserpro for expert automotive solutions. Your satisfaction is our priority."
                keywords="Contact Excserpro, Automotive support, Customer service"
                image="OpenGraph.webp"
                url="https://www.excserpro.co.za/contact"
            />

            <div
                className="welcome-section"
                style={{
                    backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('images/contact-image.webp')",
                }}
            >
                <div className="container">
                    {" "}
                    <h1>CONTACT US</h1>
                    <p>
                        Please fill in the <span>form</span> below.
                    </p>
                </div>
            </div>

            <div className="contact-section">
                {" "}
                <div className="container row">
                    <ContactForm />
                    <div className="form col-md-6">
                        <div className="details">
                            <h4>GET IN TOUCH</h4>
                            <span></span>
                        </div>
                        <div className="intouch-icons row">
                            <div className="col-12 col-md-5">
                                <i className="fa-solid fa-location-dot"></i>
                                <p>OUR LOCATION</p>
                                <span>
                                    7 Culombia Road, Evander, Mpumalanga, 2280
                                </span>
                            </div>

                            <div className="col-12 col-md-5">
                                {" "}
                                <i className="fa-solid fa-phone"></i>
                                <p>CALL US</p>
                                <span>017 632 1444</span>
                            </div>
                            <div className="col-12 col-md-5">
                                {" "}
                                <i className="fa-solid fa-envelope"></i>
                                <p>EMAIL US</p>
                                <span>info@excserpro.co.za</span>
                            </div>
                            <div className="col-12 col-md-5">
                                {" "}
                                <i className="fa-solid fa-clock"></i>
                                <p>BUSINESS HOURS</p>
                                <div>
                                    {" "}
                                    <span>Mon-Thr: 08:00am - 05:00pm</span>
                                    <span>Fri: 08:00am - 04:00pm</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
