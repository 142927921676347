import "./styles/review.css";

const Review = ({ user }) => {
    return (
        <div className="review">
            <div className="user-profile">
                <img src={user.image} alt={user.name} />
                <div className="profile">
                    <h5>{user.name}</h5>
                    <span>{user.location}</span>
                </div>
            </div>
            <div className="date">
                <img src="images/5_star.png" alt="5 Star Reviews" />
                <span>{user.date}</span>
            </div>

            <div className="actual-review">
                <p>{user.review}</p>
            </div>
        </div>
    );
};

export default Review;
