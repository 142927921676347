import { Helmet } from "react-helmet-async";

const SEOHelmet = ({ title, description, keywords, image, url }) => (
    <Helmet>
        {/* Dynamic Title */}
        <title>{title}</title>
        {/* Meta Tags */}
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content="Excserpro" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        {/* Favicon */}
        <link rel="icon" href="favicon.svg" type="image/svg+xml" />
    </Helmet>
);

export default SEOHelmet;
