import React, { useState, useEffect } from "react";

const ToggleText = () => {
    const texts = ["AUTO ELECTRICAL", "MECHANICAL"]; // Texts to toggle
    const [currentTextIndex, setCurrentTextIndex] = useState(0); // Index of the current text
    const [displayedText, setDisplayedText] = useState(""); // Text being typed/cleared
    const [isDeleting, setIsDeleting] = useState(false); // Whether it's deleting

    useEffect(() => {
        let typingTimeout;

        const handleTyping = () => {
            const fullText = texts[currentTextIndex];
            if (isDeleting) {
                // Remove characters
                setDisplayedText((prev) => prev.slice(0, -1));
                if (displayedText === "") {
                    setIsDeleting(false);
                    setCurrentTextIndex((prev) => (prev + 1) % texts.length);
                }
            } else {
                // Add characters
                setDisplayedText((prev) => fullText.slice(0, prev.length + 1));
                if (displayedText === fullText) {
                    setIsDeleting(true);
                }
            }
        };

        typingTimeout = setTimeout(handleTyping, isDeleting ? 100 : 200);

        return () => clearTimeout(typingTimeout);
    }, [displayedText, isDeleting, currentTextIndex, texts]);

    return (
        <span className="typing-effect">
            {displayedText}
            {/* <span className={`cursor ${cursorVisible ? "visible" : ""}`}>
                .
            </span> */}
        </span>
    );
};

export default ToggleText;
