import { NavLink } from "react-router-dom";
import "./styles/services.css";
import SEOHelmet from "../components/SEOHelmet";

const Services = () => {
    return (
        <>
            <SEOHelmet
                title="EXCSERPRO Services | Comprehensive Vehicle Repairs"
                description="Explore our range of services, including engine overhauls, alternator repairs, clutch repairs, and more. Excserpro ensures your vehicle is in expert hands."
                keywords="Services, Car repairs, Engine overhaul, Clutch repairs, Alternator repairs"
                image="OpenGraph.webp"
                url="https://www.excserpro.co.za/services"
            />

            <div
                className="welcome-section"
                style={{
                    backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url('images/services.webp')",
                }}
            >
                <div className="container">
                    {" "}
                    <h1>OUR SERVICES</h1>
                    <p>See our wide range of services.</p>
                </div>
            </div>

            <div className="container row service-overview">
                <div className="middle-content col-12 col-md-6">
                    <h3>WE OFFER QUALITY SERVICES</h3>
                    <span className="dash"></span>
                    <p>
                        At EXCSERPRO, we specialize in providing top-quality
                        automotive services designed to keep your vehicle
                        running at its best. Our team of skilled professionals
                        is equipped to handle all your auto electrical needs
                        with precision and care. We offer a comprehensive range
                        of services. Trust us for all your auto electrical
                        spares and specialized services that guarantee
                        performance and peace of mind.
                    </p>
                    <NavLink to={"/contact"}>QUOTE</NavLink>
                </div>
                <div className="col-12 col-md-6 featured-image">
                    <img
                        src="images/featured-image-6.webp"
                        alt="Featured car"
                    />
                </div>
            </div>

            <div className="container row services">
                <div className="middle-content">
                    {" "}
                    <h4>AUTO ELECTRICAL</h4>
                    <span className="dash"></span>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Starter Repairs</h5>
                        <i className="fa-solid fa-car-battery"></i>
                    </div>
                    <p>
                        Get your vehicle’s starter motor back in action with our
                        reliable repair services.
                    </p>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Alternator Repairs</h5>
                        <i className="fa-solid fa-tools"></i>
                    </div>
                    <p>
                        Ensure your alternator is running smoothly for
                        consistent power and performance.
                    </p>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Wiring & Rewinding</h5>
                        <i className="fa-solid fa-plug"></i>
                    </div>
                    <p>
                        Professional wiring and rewinding solutions to keep your
                        electrical systems in top shape.
                    </p>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Auto Electrical Spares</h5>
                        <i className="fa-solid fa-cogs"></i>
                    </div>
                    <p>
                        Quality auto electrical parts to keep your vehicle
                        running efficiently.
                    </p>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Battery Testing & Charging</h5>
                        <i className="fa-solid fa-battery-full"></i>
                    </div>
                    <p>
                        Accurate battery testing and charging to keep your
                        vehicle ready for the road.
                    </p>
                </div>
            </div>

            <div className="container row services">
                <div className="middle-content">
                    {" "}
                    <h4>MECHANICAL</h4>
                    <span className="dash"></span>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Car Service (minor/major)</h5>
                        <i className="fa-solid fa-car"></i>
                    </div>
                    <p>
                        Comprehensive car service solutions to ensure optimal
                        performance and safety.
                    </p>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Engine Overhaul</h5>
                        <i className="fa-solid fa-cogs"></i>
                    </div>
                    <p>
                        Revive your engine with our expert overhaul services for
                        improved efficiency.
                    </p>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Gearbox Repairs</h5>
                        <i className="fa-solid fa-cogs"></i>
                    </div>
                    <p>
                        Smooth shifting guaranteed with our gearbox repair and
                        maintenance services.
                    </p>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Clutch Repairs</h5>
                        <i className="fa-solid fa-car-side"></i>
                    </div>
                    <p>
                        Expert clutch repairs for better control and driving
                        comfort.
                    </p>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Suspension Repairs</h5>
                        <i className="fa-solid fa-car-crash"></i>
                    </div>
                    <p>
                        Restore your vehicle’s suspension for a safer and
                        smoother ride.
                    </p>
                </div>
                <div className="col-12 col-md-4">
                    {" "}
                    <div className="icon-heading">
                        <h5>Car Diagnosis</h5>
                        <i className="fa-solid fa-tools"></i>
                    </div>
                    <p>
                        Get accurate car diagnostics to identify and resolve
                        issues quickly.
                    </p>
                </div>
            </div>

            <div className="container">
                <div className="service-overview">
                    <p>
                        Perhaps your issue is not mention in the services above,
                        feel free to get in touch with us.
                    </p>
                    <NavLink to={"/contact"}>QUOTE</NavLink>
                </div>
            </div>
        </>
    );
};

export default Services;
