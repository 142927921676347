import SEOHelmet from "../components/SEOHelmet";
import "./styles/about.css";

const About = () => {
    return (
        <>
            <SEOHelmet
                title="About EXCSERPRO | Professional Auto Electrical Services"
                description="Learn more about Excserpro, a trusted provider of auto electrical and mechanical solutions, proudly accredited by MIWA and RMI."
                keywords="About Excserpro, Auto electrical services, MIWA Accredited, RMI Certified"
                image="OpenGraph.webp"
                url="https://www.excserpro.co.za/about"
            />

            <div
                className="welcome-section"
                style={{
                    backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('images/about.webp')",
                    minHeight: 350,
                }}
            >
                <div className="container">
                    {" "}
                    <h1>ABOUT US</h1>
                </div>
            </div>

            <div className="container row about-overview">
                <div className="middle-content col-12 col-md-6">
                    <h2>EXCSERPRO</h2>
                    <h3>
                        <span>EXC</span>ELLENT <span>SER</span>VICE{" "}
                        <span>PRO</span>VIDER
                    </h3>
                    <span className="dash"></span>
                    <p>
                        Proudly Woman-Owned, Providing Exceptional Auto
                        Solutions
                    </p>
                    <br />
                    <p>
                        At EXCSERPRO, we specialize in reliable auto electrical
                        and mechanical solutions, ensuring your vehicle stays in
                        top condition. From starter repairs to full engine
                        overhauls, we provide expert care with precision and
                        dedication. Conveniently located behind Lumen Electrical
                        in Evander, our team is here to keep your vehicle
                        original and running smoothly.
                    </p>
                </div>
                <div className="col-12 col-md-6">
                    <div className="owner">
                        <img
                            src="images/about_excserpro.webp"
                            alt="Gabisile Mashilwana"
                        />
                    </div>
                </div>

                <div className="middle-content col-12 col-md-6">
                    <h4>OUR VISION</h4>
                    <p>
                        To be one of the leading sources of Auto Electrical
                        Solutions in Africa.
                    </p>
                </div>

                <div className="middle-content col-12 col-md-6">
                    <h4>MISSION</h4>
                    <p>
                        To serve and provide our customers with quality &
                        service second to no-one. Ensure that all components
                        that are repaired or supplied are of the best quality.
                        Provide an efficient and professional service,
                        reinforced by stringent quality controls. Develop and
                        train our staff to achieve personal development by using
                        the resources at its disposal efficiently. Provide a
                        safe and pleasant work environment, encouraging
                        participation and growth, mutual respect, dignity and
                        job satisfaction.
                    </p>
                </div>
                <div className="middle-content col-12 col-md-6">
                    <h4>OBJECTIVES</h4>
                    <p>
                        To get involved in job creation of our people, ensuring
                        that we fight poverty for the purpose of attaining
                        prosperity. To search and acquire contract work
                        timeously. Contribute to the development of our rainbow
                        nation. To be recognized as a highly competitive
                        black-owned company in South Africa
                    </p>
                </div>
                <div className="middle-content col-12 col-md-6">
                    <h4>GROWTH</h4>
                    <p>
                        As part of our future growth strategy, we plan to use
                        every opportunity to grow our company. We also plan to
                        introduce female auto electricians as part of our
                        business.
                    </p>
                </div>

                <div className="middle-content col-12 col-md-6">
                    <h4>COMPANY STRUCTURE</h4>
                    <p>
                        The Company owners are responsible for the strategic
                        direction of the company. The company has Directors who
                        are hands-on, multiple years of experience in auto
                        electrical. Appointments are based on experience,
                        knowledge and competency.
                    </p>
                </div>
                <div className="middle-content col-12 col-md-6">
                    <h4>BBBEE and Associations</h4>
                    <p>
                        The company is also proudly a level 1 BBBEE contributor
                        with a recognised procurement level of 135%.
                    </p>
                </div>
            </div>
        </>
    );
};

export default About;
