const Footer = () => {
    return (
        <footer style={{ backgroundImage: "url('images/footer-image.webp')" }}>
            <div className="container row">
                <div className="col-12 col-md-4 details">
                    <h4>ABOUT US</h4>
                    <span></span>
                    <p>
                        <p>
                            At EXCSERPRO, we specialize in providing top-quality
                            automotive services designed to keep your vehicle
                            running at its best. Our team of skilled
                            professionals is equipped to handle all your auto
                            electrical needs with precision and care. We offer a
                            comprehensive range of services. Trust us for all
                            your auto electrical spares and specialized services
                            that guarantee performance and peace of mind.
                        </p>
                    </p>
                </div>
                <div className="col-12 col-md-4 details">
                    {" "}
                    <h4>OUR SERVICES</h4>
                    <span></span>
                    <ul>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Starter Repairs</p>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Alternator Repairs</p>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Wiring & Rewinding</p>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Auto Electrical Spares</p>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Battery Testing & Charging</p>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Car Service</p>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Engine Overhaul</p>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Gearbox Repairs</p>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Clutch Repairs</p>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Suspension Repairs</p>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i>
                            <p>Car Diagnosis</p>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-4 details">
                    {" "}
                    <h4>BUSINESS HOURS</h4>
                    <span></span>
                    <div className="business-hours">
                        <h5>Monday to Thursday</h5>
                        <p>08:00am to 05:00pm</p>
                        <h5>Friday</h5>
                        <p>08:00am to 04:00pm</p>
                    </div>
                </div>
            </div>
            <p className="copy-right">
                © Copyright 2015 - 2024. <span>EXCSERPRO</span>. All Rights
                Reserved
            </p>
        </footer>
    );
};

export default Footer;
