import "./styles/gallery.css";

const Gallery = ({ imageFile }) => {
    return (
        <div className="gallery-images">
            <img src={`gallery_images/${imageFile}`} alt={imageFile} />
        </div>
    );
};

export default Gallery;
