import "./styles/pages.css";

const PageNotFound = () => {
    return (
        <>
            <div className="coming-soon">
                <h3>
                    404 | <span>PAGE NOT FOUND</span>
                </h3>
            </div>
        </>
    );
};

export default PageNotFound;
