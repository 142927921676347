import "./styles/pages.css";

const Shop = () => {
    return (
        <>
            <div className="coming-soon">
                <h3>
                    SHOP | <span>COMING SOON</span>
                </h3>
            </div>
        </>
    );
};

export default Shop;
