import { useState } from "react";
import Loader from "./Loader";
import api from "../api";
import FormError from "./FormError";

const ContactForm = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState("");
    const [formData, setFormData] = useState({
        reason: [],
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setFormData((prevData) => {
            if (checked) {
                // Add the selected reason
                return { ...prevData, reason: [...prevData.reason, value] };
            } else {
                // Remove the deselected reason
                return {
                    ...prevData,
                    reason: prevData.reason.filter(
                        (reason) => reason !== value
                    ),
                };
            }
        });
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formError) {
            setFormError("");
        }

        setIsLoading(true);

        const token = await window.grecaptcha.execute(
            "6LfUkYMqAAAAAI5b72IaaZmxlU_svWgbXOF08RhQ",
            { action: "submit" }
        );
        const payload = {
            ...formData,
            receiver_company_email: "info@excserpro.co.za",
            receiver_comapany_name: "EXCSERPRO",
            receiver_company_website: "www.excsepro.co.za",
            receiver_company_address:
                "6 Columbia Road, Evander, Mpumalanga, 2280",
            receiver_company_phone: 27176321444,
            captchaToken: token,
        };

        console.log(payload);

        try {
            setIsLoading(true);
            const response = await api.post("/api/contact/", payload);
            console.log(response);
            setIsSubmitted(true);
        } catch (error) {
            setFormError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="form col-md-6">
            {isSubmitted ? (
                <div className="isSubmited">
                    <i className="fa-solid fa-check"></i>
                    <h3>Confirmation</h3>
                    <div>
                        <p style={{ fontSize: 14 }}>
                            <span>GREAT!</span> We have received your Enquiry
                            and will contact you as soon as possible.
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    <div className="details">
                        <h4>CONTACT US</h4>
                        <span></span>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="reason">
                                How can we help you *
                            </label>
                            <div className="checkBoxContainer">
                                {[
                                    {
                                        value: "starter repairs",
                                        label: "Starter Repairs",
                                    },
                                    {
                                        value: "alternator repairs",
                                        label: "Alternator Repairs",
                                    },
                                    {
                                        value: "wiring & rewinding",
                                        label: "Wiring & Rewinding",
                                    },
                                    {
                                        value: "auto electrical spares",
                                        label: "Auto Electrical Spares",
                                    },
                                    {
                                        value: "battery testing & charging",
                                        label: "Battery Testing & Charging",
                                    },
                                    {
                                        value: "car service",
                                        label: "Car Service",
                                    },
                                    {
                                        value: "engine overhaul",
                                        label: "Engine Overhaul",
                                    },
                                    {
                                        value: "gearbox repairs",
                                        label: "Gearbox Repairs",
                                    },
                                    {
                                        value: "clutch repairs",
                                        label: "Clutch Repairs",
                                    },
                                    {
                                        value: "suspension repairs",
                                        label: "Suspension Repairs",
                                    },
                                    {
                                        value: "car diagnosis",
                                        label: "Car Diagnosis",
                                    },
                                    { value: "other", label: "Other" },
                                ].map((option) => (
                                    <div key={option.value}>
                                        <input
                                            type="checkbox"
                                            id={option.value}
                                            name="reason"
                                            value={option.value}
                                            checked={formData.reason.includes(
                                                option.value
                                            )}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor={option.value}>
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div>
                            <label htmlFor="name">Name *</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="email">Email (Optional)</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div>
                            <label htmlFor="phone">Phone Number *</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="message">Message *</label>
                            <textarea
                                id="message"
                                name="message"
                                rows="6"
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                            ></textarea>
                        </div>

                        {formError ? <FormError errorText={formError} /> : ""}

                        <button type="submit">
                            {isLoading ? <Loader /> : "Send Message"}
                        </button>
                        <div className="google-policy">
                            {" "}
                            <p>
                                {" "}
                                <small>
                                    This site is protected by reCAPTCHA and the
                                    Google
                                    <a href="https://policies.google.com/privacy">
                                        Privacy Policy
                                    </a>{" "}
                                    and
                                    <a href="https://policies.google.com/terms">
                                        Terms of Service
                                    </a>{" "}
                                    apply.
                                </small>
                            </p>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
};

export default ContactForm;
