import "./FontAwesome/css/all.css";
import "./styles/Bootstrap-grid.css";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";

import Contact from "./pages/Contact";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Shop from "./pages/Shop";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import { useEffect, useState } from "react";
import PageNotFound from "./pages/PageNotFound";
import { HelmetProvider } from "react-helmet-async";

function App() {
    const [scrolled, setScrolled] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 293 && window.innerWidth <= 768) {
                setScrolled(true);
                setIsMobile(true);
            } else if (window.scrollY >= 85 && window.innerWidth > 768) {
                setScrolled(true);
                setIsMobile(false);
            } else {
                setScrolled(false);
                setIsMobile(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const contentMargin = () => {
        if (scrolled && isMobile) {
            return { marginTop: 395.11 };
        } else if (scrolled && !isMobile) {
            return { marginTop: 153 };
        } else {
            {
            }
        }
    };

    return (
        <>
            <HelmetProvider>
                <ScrollToTop />
                <Nav />
                <section style={contentMargin()}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/appointment" element={<Contact />} />
                        <Route path="/shop" element={<Shop />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </section>
                <Footer />
            </HelmetProvider>
        </>
    );
}

export default App;
