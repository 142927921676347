import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/SimpleSlider.css";

function SimpleSlider({ children, dots, lazyLoad }) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const screenSizeValue = () => {
        if (screenWidth < 767) {
            return { slidesToShow: 1, slidesToScroll: 1 };
        } else if (screenWidth >= 768 && screenWidth <= 1200) {
            return { slidesToShow: 3, slidesToScroll: 3 };
        } else {
            return { slidesToShow: 4, slidesToScroll: 4 };
        }
    };

    const settings = {
        dots: dots,
        lazyLoad: lazyLoad,
        infinite: true,
        speed: 200,
        slidesToShow: screenSizeValue().slidesToShow,
        slidesToScroll: screenSizeValue().slidesToScroll,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
    };
    return <Slider {...settings}>{children}</Slider>;
}

export default SimpleSlider;
