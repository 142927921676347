import ToggleText from "../components/ToogleText";
import "./styles/pages.css";
import "./styles/home.css";
import { NavLink } from "react-router-dom";
import SimpleSlider from "../components/SimpleSlider";
import Review from "../components/Review";
import Gallery from "../components/Gallery";
import SEOHelmet from "../components/SEOHelmet";

const Home = () => {
    const usersReviews = [
        {
            name: "Petros Mkhwanazi",
            image: "google_reviews_images/unnamed.png",
            location: "South Africa",
            date: "2 weeks ago",
            review: "They are very good at their work and I trust them now , if you want a good service please contact them",
        },
        {
            name: "Thandekile Agnes Zwane",
            image: "google_reviews_images/unnamed (2).png",
            location: "South Africa",
            date: "11 months ago",
            review: "",
        },
        {
            name: "Jacqui Burger",
            image: "google_reviews_images/unnamed (3).png",
            location: "South Africa",
            date: "a year ago",
            review: "I highly recommend Excserpro. They are affordable, professional, and friendly, and they don't take forever to get the job done. Thank you to each and every staff member. You are great!!!!!!!!",
        },
        {
            name: "zanele millicent",
            image: "google_reviews_images/unnamed (4).png",
            location: "South Africa",
            date: "a year ago",
            review: "",
        },
        {
            name: "Arega Abera",
            image: "google_reviews_images/unnamed (5).png",
            location: "South Africa",
            date: "a year ago",
            review: "",
        },
        {
            name: "Me Me",
            image: "google_reviews_images/unnamed (6).png",
            location: "South Africa",
            date: "2 years ago",
            review: "",
        },
        {
            name: "anthony van wyngaard",
            image: "google_reviews_images/unnamed (7).png",
            location: "South Africa",
            date: "3 years ago",
            review: "This is the best car electric repair shop i won't use someone else they are very professional #thebest",
        },
    ];

    const galleryList = [
        "001_2.jpg",
        "01.jpg",
        "03.jpg",
        "04.jpg",
        "15941441_1703588336599081_6855517563876504620_n.jpg",
        "1731060343663.jpg",
        "1731060343681.jpg",
        "1731060343696.jpg",
        "1731060343714.jpg",
        "1731060343731.jpg",
        "1731060343751.jpg",
        "1731060343767.jpg",
        "1731060343785.jpg",
        "1731060343803.jpg",
        "1731060343819.jpg",
        "1731060343838.jpg",
        "1731060343855.jpg",
        "1731060343870.jpg",
        "1731060343886.jpg",
        "1731060343904.jpg",
        "1731060343915.jpg",
        "20171113_084314.jpg",
        "20171123_091814.jpg",
        "20230613_081755.jpg",
        "20230613_081801.jpg",
        "23722739_1850603805230866_8622120516996118920_n1.jpg",
        "23722739_1850603805230866_8622120516996118920_n.jpg",
        "23795597_1850619108562669_3901119938178002036_n.jpg",
        "A6-Flyer-Brochure.jpg",
        "about_excserpro.jpg",
        "diagnosis_1.jpg",
        "DSC02539.jpg",
        "DSC02588.jpg",
        "excserpro_logo1.png",
        "excserpro_logo.png",
        "icon_repairs.png",
        "icon_wiring.png",
        "IMG-20171219-WA0013.jpg",
        "IMG_20180926_142914.jpg",
        "IMG_20181015_165920.jpg",
        "IMG_20181108_092827.jpg",
        "IMG_20181108_092844.jpg",
        "IMG_20181108_140230.jpg",
        "IMG_20181108_140257.jpg",
        "IMG_20181114_111807.jpg",
        "IMG-20181115-WA0003.jpg",
        "IMG_20181205_122255.jpg",
        "IMG_20190123_082351.jpg",
        "IMG-20190123-WA0000.jpg",
        "IMG-20190123-WA0001.jpg",
        "IMG_20190723_122925.jpg",
        "IMG_20190823_161305.jpg",
        "IMG_20190823_161311.jpg",
        "IMG_20190823_161319.jpg",
        "IMG_20190909_081330.jpg",
        "IMG_20190909_081353.jpg",
        "IMG_20191017_082757.jpg",
        "IMG_20191022_092317.jpg",
        "IMG_20191022_092341.jpg",
        "IMG-20241119-WA0003.jpg",
        "IMG-20241119-WA0004.jpg",
        "IMG-20241119-WA0005.jpg",
        "IMG-20241119-WA0006.jpg",
        "IMG-20241119-WA0007.jpg",
        "IMG_7933.jpg",
        "IMG_7934.jpg",
        "IMG_7935.jpg",
        "IMG_7942.jpg",
        "IMG_7947.jpg",
        "IMG_7950.jpg",
        "IMG_7997.jpg",
        "IMG_8000.jpg",
        "IMG_8004.jpg",
        "IMG_8005.jpg",
        "IMG_8006.jpg",
        "IMG_8007.jpg",
        "IMG_8008.jpg",
        "IMG_8014.jpg",
        "IMG_8015.jpg",
        "IMG_8018.jpg",
        "IMG_8019.jpg",
        "IMG_8020.jpg",
        "IMG_8021.jpg",
        "IMG_8022.jpg",
        "IMG_8023.jpg",
        "IMG_8024.jpg",
        "IMG_8025.jpg",
        "LD_Alt.png",
        "LD_Stators1.png",
        "LD_Stators.png",
        "remanufacturing.jpg",
        "spares.jpg",
        "starter_1.jpg",
        "starter.jpg",
        "WhatsApp-Image-2017-12-11-at-12.27.09-PM.jpeg",
        "WhatsApp-Image-2017-12-11-at-12.28.02-PM.jpeg",
    ];

    return (
        <>
            <SEOHelmet
                title="EXCSERPRO | The Source of Auto Electrical & Mechanical Solutions"
                description="EXCSERPRO offers expert auto electrical and mechanical services. Accredited by MIWA & RMI, we provide high-quality repairs, diagnostics, and maintenance for your vehicle."
                keywords="Auto electrical services, Mechanical services, Vehicle diagnostics, Car repairs, Engine overhaul, Clutch repairs, Alternator repairs, Excserpro, MIWA Accredited, RMI Certified"
                image="OpenGraph.webp"
                url="https://www.excserpro.co.za"
            />
            <div
                className="welcome-section"
                style={{
                    backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('images/home-image.webp')",
                    minHeight: 400,
                }}
            >
                <div className="container">
                    {" "}
                    <h1>
                        The Source of <ToggleText /> Solutions
                    </h1>
                    <p>
                        Proudly Accredited by MIWA & RMI. With us, your vehicle
                        is in good hands.
                    </p>
                    <img src="images/Associations.webp" alt="Association" />
                    <NavLink to={"/contact"}>CONTACT NOW</NavLink>
                </div>
            </div>

            <div className="container">
                <div className="middle-content">
                    <h2>WHY CHOOSE US</h2>
                    <span className="dash"></span>
                    <p>
                        As a certified member of leading automotive
                        associations, we uphold the highest standards in service
                        and quality. Our dedication to professionalism ensures
                        that your vehicle is maintained with precision and
                        expertise, giving you peace of mind on every journey.
                    </p>

                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="icon-heading">
                                <h5>Modern Workshop</h5>
                                <i className="fa-solid fa-bus"></i>
                            </div>
                            <p>
                                Our workshop combines the latest in automotive
                                technology with top-tier expertise to deliver
                                unmatched service quality.
                            </p>
                            <div className="icon-heading">
                                <h5>Talented Workers</h5>
                                <i className="fa-solid fa-helmet-safety"></i>
                            </div>
                            <p>
                                Our team of highly trained and certified
                                technicians brings unmatched expertise to every
                                vehicle we service.
                            </p>
                        </div>
                        <div className="col-12 col-md-4">
                            {" "}
                            <div className="icon-heading">
                                <h5>Quick Support</h5>
                                <i className="fa-solid fa-clock"></i>
                            </div>
                            <p>
                                Our dedicated support team is here to provide
                                you with immediate assistance, ensuring your
                                concerns are addressed without delay.
                            </p>
                            <div className="icon-heading">
                                <h5>Quality Repair</h5>
                                <i className="fa-solid fa-wrench"></i>
                            </div>
                            <p>
                                At the heart of our service is an unwavering
                                commitment to excellence. Every repair we
                                undertake is performed with meticulous attention
                                to detail, using advanced technology and the
                                highest-quality parts. Our skilled technicians
                                treat your vehicle as if it were their own,
                                ensuring that every fix not only meets but
                                exceeds industry standards. With us, quality
                                isn't just a promise—it's a guarantee you can
                                trust.
                            </p>
                        </div>
                        <div className="col-12 col-md-4">
                            <img
                                src="images/gabi-pointing.webp"
                                alt="Mrs Ndlovu
                            "
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="costomers-served"
                style={{
                    backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('images/scroll-image.webp')",
                }}
            >
                <div className="container row">
                    <div className="col-12 col-md-3">
                        <h2>784</h2>
                        <span className="dash"></span>
                        <p>VEHICLES SERVED</p>
                    </div>
                    <div className="col-12 col-md-3">
                        {" "}
                        <h2>1241</h2>
                        <span className="dash"></span>
                        <p>HAPPY CUSTOMERS</p>
                    </div>

                    <div className="col-12 col-md-3">
                        {" "}
                        <h2>1182</h2>
                        <span className="dash"></span>
                        <p>PROBLEMS SOLVED</p>
                    </div>
                    <div className="col-12 col-md-3">
                        <NavLink to={"/services"}>SERVICES</NavLink>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="middle-content">
                    <h2>WORDS FROM OUR CUSTOMERS</h2>
                    <span className="dash"></span>
                    <p>
                        Here is what some of our customers have to say about us
                        on Google Reviews
                    </p>{" "}
                </div>
            </div>

            <div className="container">
                {" "}
                <SimpleSlider dots={true} lazyLoad={false}>
                    {usersReviews.map((user) => (
                        <Review key={user.name} user={user} />
                    ))}
                </SimpleSlider>
            </div>

            <div
                className="welcome-section gallery"
                style={{
                    backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('images/about.webp')",
                    minHeight: 400,
                }}
            >
                <div className="container">
                    <div className="middle-content">
                        {" "}
                        <h2>WORK GALLERY</h2>
                        <span className="dash"></span>
                        <p>Take a look at our gallery.</p>
                    </div>
                    <SimpleSlider dots={false} lazyLoad={true}>
                        {galleryList.map((image_file) => (
                            <Gallery imageFile={image_file} />
                        ))}
                    </SimpleSlider>
                </div>
            </div>

            <div className="container" style={{ margin: "50px auto" }}>
                <img
                    style={{ width: "100%" }}
                    src="images/products.webp"
                    alt="Products"
                />
            </div>
        </>
    );
};

export default Home;
